import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/privateRoute"
import Admin from "../components/ref-admin/Admin"
import ManageMembers from '../components/ref-admin/ManageMembers'
import ManageGallery from '../components/ref-admin/ManageGallery'
import Login from "../components/login"

const SiteAdmin = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/site-admin/ref-admin" component={Admin} />
      <PrivateRoute path="/site-admin/ref-admin/empresarias" component={ManageMembers} />
      <PrivateRoute path="/site-admin/ref-admin/galeria" component={ManageGallery} />
      <Login path="/site-admin/login" />
    </Router>
  </Layout>
)

export default SiteAdmin