import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { css } from "emotion";
import firebase from "firebase/app";
import { Link } from "gatsby";

import firestore from "../../utils/firestore";
import Header from "./Header";

const columns = css({
  padding: '1em'
});

const img = css({
  objectFit: 'fill'
});

const modal = css({
  '.modal-card-head': {
    backgroundColor: '#C7EBBC',
    paddingBottom: '0'
  }
});

const ManageGallery = () => {

  let [storageRef, setStorageRef] = useState(firestore.storageRef);
  let [images, setImages] = useState([]);
  let [selectedImg, setSelectedImg] = useState({});
  let [loading, setLoading] = useState(false);
  let [notification, setNotification] = useState({ message: '', isError: false });
  let [isEditing, setIsEditing] = useState(false);
  let [uploadedFile, setUploadedFile] = useState(null);
  let [uploadProgress, setUploadProgress] = useState(0);
  let [isModalOpen, setIsModalOpen] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  console.log(errors);

  useEffect(() => {

    // const user = firestore.auth.currentUser;

    const fetchData = async () => {
      try {
        setLoading(true);

        const imagesData = await getImagesData();

        setImages(imagesData);

        setLoading(false);
      } catch (err) {
        console.error(err);
        setNotification({ message: 'Error cargando imágenes. Intente luego.', isError: true });
      }

    };

    if(!images.length) fetchData();
    setImages(images.sort((a, b) => parseInt(a.order) >= parseInt(b.order) ? 1 : -1));

  }, [images]);

  const getImagesData = async () => {

    const listRef = storageRef.child('Gallery');

    const res = await listRef.listAll();
    const items = res.items.map(item => item);
    const imgItems = [];

    for (let item of items) {
      const url = await item.getDownloadURL();
      const metadata = await item.getMetadata();
      item = Object.assign(item, metadata.customMetadata);

      imgItems.push({
        name: item.name,
        url: url,
        author: item.author,
        title: item.title,
        description: item.description,
        price: item.price,
        order: item.order,
        instagram: item.instagram
      });
    }

    return imgItems.sort((a, b) => parseInt(a.order) >= parseInt(b.order) ? 1 : -1);

  }

  const reorderImgList = () => {
    setImages(images.sort((a, b) => parseInt(a.order) >= parseInt(b.order) ? 1 : -1));
  }

  const updateImgMetadata = async () => {
    try {
      console.log({selectedImg, images})
      const ref = storageRef.child(`Gallery/${selectedImg.name}`);

      const metadata = {
        customMetadata: {
          author: selectedImg.author,
          title: selectedImg.title,
          price: selectedImg.price,
          description: selectedImg.description,
          instagram: selectedImg.instagram,
          order: selectedImg.order
        }
      }

      const updatedIndex = images.findIndex(img => img.name === selectedImg.name);
      images[updatedIndex] = {
        name: selectedImg.name,
        ...metadata.customMetadata
      }
      setImages(images);
      reorderImgList();

      return await ref.updateMetadata(metadata);

    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  const changeImage = (ev) => {
    ev.preventDefault();
    const name = ev.currentTarget.value;

    const img = images.find(img => img.name === name);
    setSelectedImg(img);
  }

  const handleEditing = (ev) => {
    const field = ev.currentTarget.name;
    const editing = field === 'isEditing';
    setSelectedImg(editing ? images[0] : {});
    setUploadProgress(0);
    setUploadedFile(null);
    setIsEditing(editing);
  }

  const handleFormChange = (ev) => {
    ev.preventDefault();
    const value = ev.currentTarget.value;
    const field = ev.currentTarget.name;

    setSelectedImg({ ...selectedImg, [field]: value });
  }

  const resetFields = () => {
    if(isEditing) {
      setSelectedImg(images.find(img => img.name === selectedImg.name));
    } else {
      setSelectedImg({});
    }
  }
  

  const onSubmit = async (ev) => {
    try {
      if (isEditing) {

        const res = await updateImgMetadata();
        setNotification(res ? { message: `¡Datos de "${selectedImg.title}" actualizados!`, isError: false } : { message: `No se pudieron actualizar los datos de "${selectedImg.title}". Intente luego.`, isError: true });
  
      } else {
        if(uploadedFile) {
          await saveImage();
  
        } else {        
          setNotification({ message: 'Selecciona una imagen', isError: true });
  
        }
      }
    } catch(err) {
      console.error(err);
      setNotification({ message: 'Ocurrió un error. Intente luego.', isError: true });
    }
    
  }

  const clearNotification = () => setNotification({ message: '', isError: false });

  const handleFile = async files => {
    if(files[0]) {
      setUploadedFile(files[0]);
      setSelectedImg({ ...selectedImg, url: URL.createObjectURL(files[0]) });
    }
  }

  const saveImage = async () => {
    const metadata = {
      customMetadata: {
        author: selectedImg.author,
        title: selectedImg.title,
        description: selectedImg.description,
        price: selectedImg.price,
        instagram: selectedImg.instagram,
        order: selectedImg.order
      }
    }

    const now = Date.now();

    const uploadTask = storageRef.child(`Gallery/${now}-${uploadedFile.name}`).put(uploadedFile, metadata);

    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgress(progress);
      console.log('Upload is ' + progress + '% done');

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    }, error => {
      setNotification({ message: `No se pudo almacenar "${selectedImg.title}". Intente luego.`, isError: true });
      console.error(error);
      throw error;

    }, async () => {
      const url = await uploadTask.snapshot.ref.getDownloadURL();

      const newImage = {
        name: `${now}-${uploadedFile.name}`,
        url: url,
        ...metadata.customMetadata
      };

      setImages([...images, newImage]);
      setUploadProgress(0);
      setUploadedFile(null);
     
      setNotification({ message: `¡"${selectedImg.title}" ha sido almacenada exitosamente!`, isError: false });
    });

  };

  const deleteSelectedImage = async () => {
    try {
      setLoading(true);
      console.log({selectedImg, images})
      const ref = storageRef.child(`Gallery/${selectedImg.name}`);
      await ref.delete();
      setIsModalOpen(false);
      setImages(images.filter(img => img.name !== selectedImg.name));
      setSelectedImg(images[0]);
      setLoading(false);
      
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Error eliminando imagen. Intente luego.', isError: true });
    }
  }

  return (
    <div className="content">
      <Header disablePublishButton={true} />
      <div className={`columns ${columns}`}>
        <div className="column">

          <div className="notification is-primary is-light">
            
            <div className="control is-flex is-justify-content-space-evenly">
              <label className="checkbox" hidden={loading}>
                <input type="checkbox"
                  className="checkbox"
                  checked={isEditing}
                  name="isEditing"
                  onChange={handleEditing}
                  disabled={images.length <= 0 || uploadProgress != 0}
                />Editar</label>
                <br />
                <label className="is-help" hidden={!loading}>Cargando imágenes...</label>

              <label className="checkbox">
                <input type="checkbox"
                  className="checkbox"
                  checked={!isEditing}
                  name="including"
                  onChange={handleEditing}
                />Incluir</label>
            </div>

            <div className={`field ${!isEditing && 'is-hidden'}`}>
              <label className="label">Seleccionar imagen</label>
              <div className={`select is-primary is-flex ${loading ? 'is-loading' : ''}`}>
                <select value={selectedImg.name} onChange={changeImage} style={{width: '100%'}}>
                  {
                    images.map((img, i) => <option key={i} value={img.name}>{`${img.order}. ${img.author}, ${img.title}`}</option>)
                  }
                </select>
              </div>
            </div>

            <div className={`${isEditing && 'is-hidden'}`}>
              <label className="label">Subir imagen</label>
              <div className="file">
                <label className="file-label">
                  <input type="file" className="file-input" accept="image/*" style={{ outline: 'none' }} onChange={e => handleFile(e.target.files)} />
                  <span className="file-cta">
                    <span className="file-label">
                      Elegir archivo...
                  </span>
                  </span>
                </label>
              </div>
              <br />
              <progress className="progress is-link" value={uploadProgress} max="100" hidden={uploadProgress === 0}>30%</progress>
            </div>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)} className="box">          

            <div className="field">
              <label className="label">Autora</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Autora"
                  name="author"
                  defaultValue={''}
                  value={selectedImg["author"] || ''}
                  onChange={handleFormChange}
                  ref={register({ required: true, maxLength: 100 })} />
                {errors["author"] && <span className="help is-danger">Ingresá un nombre</span>}
              </div>
            </div>

            <div className="field">
              <label className="label">Título</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Título"
                  name="title"
                  defaultValue={''}
                  value={selectedImg["title"] || ''}
                  onChange={handleFormChange}
                  ref={register({ required: true, maxLength: 200 })} />
                {errors["title"] && <span className="help is-danger">Ingresá el título</span>}
              </div>
            </div>

            <div className="field">
              <label className="label">Descripción</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Descripción"
                  name="description"
                  defaultValue={''}
                  value={selectedImg["description"] || ''}
                  onChange={handleFormChange}
                  ref={register({ required: true, maxLength: 200 })} />
                {errors["description"] && <span className="help is-danger">Ingresá la descripción</span>}
              </div>
            </div>

            <div className="field">
              <label className="label">Precio</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Precio"
                  name="price"
                  defaultValue={''}
                  value={selectedImg["price"] || ''}
                  onChange={handleFormChange}
                  ref={register({ required: true, maxLength: 10 })} />
                {errors["price"] && <span className="help is-danger">Ingresá el precio</span>}
              </div>
            </div>

            <div className="field">
              <label className="label">Instagram</label>

              <div className="control is-flex">
                <a className="button is-static">@</a>
                <input
                  className="input"
                  type="text"
                  placeholder="Instagram"
                  name="instagram"
                  defaultValue={''}
                  value={selectedImg["instagram"] || ''}
                  onChange={handleFormChange}
                  ref={register({ required: true, maxLength: 100 })} />
                {errors["instagram"] && <span className="help is-danger">Ingresá el ide de Instagram</span>}
              </div>
            </div>

            <div className="field">
              <label className="label">Orden</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder="Orden"
                  name="order"
                  defaultValue={0}
                  value={selectedImg["order"] || 0}
                  onChange={handleFormChange}
                  ref={register({ required: true, min: 1, pattern: '^[1-9][0-9]*$' })} />
                {errors["order"] && <span className="help is-danger">Ingresá un número mayor a 0</span>}
              </div>
            </div>

            <div className="field" hidden>
              <label className="label">URL</label>
              <div className="control">
                <input
                  disabled={true}
                  className="input"
                  type="text"
                  placeholder="URL"
                  name="url"
                  value={selectedImg["url"] || ''}
                  onChange={handleFormChange}
                  ref={register()} />
              </div>
            </div>

            <div className="field is-grouped is-justify-content-space-between">
              <div className="control">
                <button type="submit" className={`button is-primary ${loading && 'is-loading'}`} style={{ color: '#fff' }}>{isEditing ? 'Actualizar' : 'Subir'}</button>
              </div>
              <div className="control">
                <button type="button" className={`button is-primary is-light ${loading && 'is-loading'}`} onClick={() => resetFields()}>{isEditing ? 'Cancelar' : 'Limpiar'}</button>
              </div>
              <div className="control">
                <button type="button" className={`button is-danger ${loading && 'is-loading'}`} onClick={() => setIsModalOpen(true)} disabled={!isEditing}>Eliminar</button>
              </div>
            </div>

            <div className={`notification ${notification.isError ? 'is-danger' : 'is-success'}`} hidden={!notification.message}>
              <button type="button" className="delete" onClick={() => clearNotification()}></button>
              {notification.message}
            </div>

            <div className={`modal ${isModalOpen && 'is-active'} ${modal}`}>
              <div className="modal-background"></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title has-text-centered">Confirmación</p>
                  <button className="delete" aria-label="close-right" onClick={() => setIsModalOpen(false)}></button>
                </header>
                <section className="modal-card-body has-text-centered">
                  ¿Desea eliminar la imagen?
                  
                  <div className="field is-grouped is-justify-content-center">
                    <div className="control">
                      <button type="button" className="button is-danger" onClick={() => deleteSelectedImage()}>Eliminar</button>
                    </div>
                    <div className="control">
                      <button type="button" className="button is-primary" style={{ color: '#fff' }} onClick={() => setIsModalOpen(false)}>Cancelar</button>
                    </div>
                  </div>
                </section>
              </div>
            </div>

          </form>
        </div>

        <div className="column  is-two-thirds">
          <div className="box">
            {!isEditing ?
             <label className="label">Vista previa</label>
            :
            <label className="label">Imagen seleccionada</label>
            }
            <figure className="image">
              <img src={selectedImg.url} className={img} />
            </figure>
          </div>

        </div>
      </div>

      <div className="is-flex is-justify-content-center">
        <Link className="button has-text-primary" to={'/site-admin/ref-admin'} rel="back">
          {'< Regresar'}
        </Link>
      </div>
      <br />
    </div>

  )
}

export default ManageGallery;