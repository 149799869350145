import firestore from '../utils/firestore';
import { navigate } from "gatsby"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  firestore.auth.onAuthStateChanged(user => {
    if (user) {
      navigate(`/site-admin/ref-admin`)
    }
  });

  return new Promise((resolve, reject) => {
    firestore.auth.signInWithEmailAndPassword(username, password)
      .then((res) => {
        setUser({
          username: username,
          name: username,
          email: username,
        });
        resolve();
      })
      .catch(function (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        
        reject(errorCode, errorMessage)
      });
  })

}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  firestore.auth.signOut()
    .then(function () {

    })
    .catch(function (error) {
    });
  setUser({})
  callback()
}