import React, { useState } from 'react'
import { css } from 'emotion';
import { navigate } from 'gatsby'
import axios from 'axios';

import { getUser, isLoggedIn, logout } from "../../services/auth";
import Title from '../Title';

const main = css({

  '.header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5em',

    section: {
      margin: '0 auto'
    },

    'title': {
      margin: '0 auto'
    }
  },

  '.notification': {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end'
  },

  '@media all and (max-width: 450px)': {
    '.header': {
      display: 'inline'
    }
  }

});

const Header = ({ disablePublishButton = false }) => {
  let [build, setBuild] = useState('');

  const handleLogout = () => {
    logout(() => navigate(`/site-admin/login`))
  }

  const triggerBuild = () => {
    axios.post('https://api.netlify.com/build_hooks/5c0366a567610c0217a03c52')
      .then(() => setBuild('Actualizando sitio web...'))
      .catch(() => setBuild('Ocurrió un error.'))
  }

  return (
    <section className={main}>
      <div className="header">
        <Title title="Panel de Administración" />
        <div className="notification">
          {
            isLoggedIn() && <p>{`Hola, ${getUser().name}`}</p>
          }
          {
            isLoggedIn() ? (
              <button className="button is-primary-invert logout"
                onClick={handleLogout}
              >Salir
              </button>
            ) : null
          }
          <br />
          <button className="button has-text is-primary" style={{ color: '#fff' }} onClick={triggerBuild} disabled={disablePublishButton} >
            Publicar cambios
          </button>
          <p className="help is-danger has-text-centered">{build}</p>
        </div>


      </div>
    </section>
  )
}

export default Header;