import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import { navigate } from 'gatsby';
import axios from 'axios';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Link } from 'gatsby';

import firestore from '../../utils/firestore';
import RefForm from '../RefForm';
import Header from './Header';

// import "../../node_modules/react-table/react-table.css";

const main = css({
  paddingBottom: '1em',

  '.rt-tr-group': {
    cursor: 'pointer'
  },

  '.ReactTable.-striped .rt-tr.-odd': {
    background: 'rgb(222, 216, 231, .3)'
  },

  '.ReactTable .rt-thead.-header': {
    background: 'rgb(199,235,188, .3)'
  },

  '.notification': {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end'
  }

});

function Table({ columns, data, getRowProps }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => (
            prepareRow(row) || (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            )
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

const ManageMembers = () => {

  let [allMembers, setAllMembers] = useState([]);
  let [products, setProducts] = useState([]);
  let [services, setServices] = useState([]);
  let [countries, setCountries] = useState([]);
  let [member, setMember] = useState({});
  let [build, setBuild] = useState('');

  useEffect(() => {
    const allMembers = [];
    const products = [];
    const services = [];
    const countries = [];

    firestore.members.orderBy('create_date', 'desc').get().then(snapshot => {
      snapshot.forEach(doc => {
        allMembers.push({ ...doc.data(), id: doc.id })
      });

      firestore.products.get().then(snapshot => {
        snapshot.forEach(doc => {
          products.push({ ...doc.data(), id: doc.id })
        });

        firestore.services.get().then(snapshot => {
          snapshot.forEach(doc => {
            services.push({ ...doc.data(), id: doc.id })
          });

          firestore.countries.get().then(snapshot => {
            snapshot.forEach(doc => {
              countries.push({ ...doc.data(), id: doc.id })
            });

            setCountries(countries)
            setAllMembers(allMembers)
            setProducts(products)
            setServices(services)
          });

        });
      });
    });
  }, []);

  const logout = () => {
    logout(() => navigate(`/site-admin/login`))
  }

  const triggerBuild = () => {
    axios.post('https://api.netlify.com/build_hooks/5c0366a567610c0217a03c52')
      .then(() => setBuild('Actualizando sitio web...'))
      .catch(() => setBuild('Ocurrió un error.'))
  }
  /*
    const updateMembers = () => {
  
      allMembers.forEach(member => { member.location.codigo = '506'; member.location.pais = 'Costa Rica' })
      allMembers.forEach(member => {
        const ref = firestore.members.doc(member.id);
        ref.update(member)
          .then(() => console.log(`Updated => ${member.name}. ${member.id}`))
          .catch(() => console.log(`Error updating ${member.name}, ${member.id}`));
      });
  
      allMembers.forEach(member => { member.phone_code = '506'; member.business_phone_code = '506' })
      allMembers.forEach(member => {
        const ref = firestore.members.doc(member.id);
        ref.update(member)
          .then(() => console.log(`Updated => ${member.name}. ${member.id}`))
          .catch(() => console.log(`Error updating ${member.name}, ${member.id}`));
      });
    }*/

  const enabledCount = allMembers.filter(member => member.enabled).length;
  const disabledCount = allMembers.filter(member => !member.enabled).length;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Correo',
        accessor: 'email',
      },
      {
        Header: 'Nombre empresa',
        accessor: 'business_name',
      },
      {
        Header: 'Nombre empresa (único)',
        accessor: 'business_name_lc',
      },
      {
        Header: 'Habilitada',
        accessor: d => d.enabled ? 'Habilitada' : 'Deshabilitada'
      },
    ],
    []
  )

  return (
    <div className={`content ${main}`}>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <Header />
          {/* <button className="button has-text is-primary" style={{ color: '#fff' }} onClick={updateMembers}>Update members</button> */}
          <p>Existen {enabledCount} Empresarias habilitadas y {disabledCount} deshabilitadas.</p>
          <div className="column has-text-centered">
            <Link className="button has-text-primary" to={'/site-admin/ref-admin'} rel="back">
              {'< Regresar'}
            </Link>
          </div>

          <Table
            columns={columns}
            data={allMembers}
            getRowProps={row => ({
              onClick: () => setMember(allMembers.find(m => m.business_name_lc === row.values.business_name_lc) || null),
              style: {
                cursor: "pointer"
              }
            })} />
          <div /* ref={formRef} */>{
            allMembers.length && products.length && services.length && member ?
              <RefForm data={{ ...member }} products={products} services={services} countries={countries} /> : <h2>Seleccione una empresaria</h2>
          }
          </div>

        </div>
      </div>
      <div className="column has-text-centered">
        <Link className="button has-text-primary" to={'/site-admin/ref-admin'} rel="back">
          {'< Regresar'}
        </Link>
      </div>
    </div>
  )
}

export default ManageMembers;
