import React from 'react';
import { Link } from 'gatsby';
import Header from './Header';

const Admin = () => {
  return (
    <div className="content">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <Header />
          <br />

          <p className="has-text-justified">Seleccione una opción:</p>

          <div className="columns">
            <div className="column">
              <Link className="button is-flex is-medium is-primary is-light" to="/site-admin/ref-admin/empresarias">
                Ver Empresarias
              </Link>
            </div>
            <div className="column">
              <Link className="button is-flex is-medium is-success is-light" to="/site-admin/ref-admin/galeria">
                Ver Galería
              </Link>
            </div>
          </div>
          
          <br /><br />

        </div>
      </div>
    </div>
  )
}

export default Admin;
