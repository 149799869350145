import React from "react"
import { navigate } from "gatsby"
import { css } from 'emotion'
import Title from '../components/Title'
import { handleLogin, isLoggedIn } from "../services/auth"
import firestore from '../utils/firestore'

const main = css({
  '.control': {
    textAlign: 'center'
  }
})
class Login extends React.Component {
  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      showMessage: false,
      forgotPassword: false,
      lostEmailPassword: '',
      message: 'a long test'
    }
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (!this.state.username || !this.state.password) return;

    handleLogin(this.state)
      .then(() => {
        navigate(`/site-admin/ref-admin`)
      })
      .catch(() => this.setState({ message: 'El usuario o contraseña no es correcto.', showMessage: true }))
  }

  sendPasswordResetEmail = () => {
    const auth = firestore.auth;
    this.setState({ message: `Enviando correo a ${this.state.lostEmailPassword}...`, showMessage: true });

    auth.sendPasswordResetEmail(this.state.lostEmailPassword).then(() => {

      this.setState({ message: '¡Correo enviado!', lostEmailPassword: '', forgotPassword: false });

    }).catch((error) => {
      console.error(error);
      this.setState({ message: 'Ocurrió un error. Intenta de nuevo luego.'});
    });
  }

  handleForgotPassword = () => {
    const { forgotPassword } = this.state;

    this.setState({ forgotPassword: !forgotPassword });
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/site-admin/ref-admin`)
    }

    return (
      <section className={main}>
        <div className="content">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <section className="section">

                <Title title="Panel de Administración" />
                <form onSubmit={event => {
                  this.handleSubmit(event)
                }}
                >
                  <div hidden={this.state.forgotPassword}>
                    <div className="field">
                      <label className="label">Correo *</label>
                      <div className="control">
                        <input
                          className={`input ${!this.state.username && 'is-danger'}`}
                          type="text"
                          name="username"
                          autoComplete="username"
                          placeholder={'Correo'}
                          onChange={this.handleUpdate}
                          value={this.state.username}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Contraseña *</label>
                      <div className="control">
                        <input
                          className={`input ${!this.state.password && 'is-danger'}`}
                          type="password"
                          name="password"
                          autoComplete="password"
                          placeholder={'Contraseña'}
                          onChange={this.handleUpdate}
                          value={this.state.password}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="has-text-centered" hidden={!this.state.forgotPassword}>

                    <div className="field" >
                      <div className="control">
                        <input
                          className={`input ${!this.state.lostEmailPassword && this.state.forgotPassword && 'is-danger'}`}
                          type="text"
                          name="lostEmailPassword"
                          autoComplete="username"
                          placeholder={'Correo'}
                          onChange={this.handleUpdate}
                          value={this.state.lostEmailPassword}
                        />
                      </div>

                      <div className="field">
                        <div className="control">
                          <br />
                          <button className="button is-primary" style={{ color: '#fff' }} value="sendEmail" onClick={this.sendPasswordResetEmail} >Enviar correo de recuperación</button>
                        </div>
                      </div>

                    </div>

                  </div>

                  <hr />

                  <div className="control" hidden={this.state.forgotPassword}>
                    <button className="button is-primary" style={{ color: '#fff' }} type="submit" value="Log In">Ingresar</button>
                  </div>

                  <div className="control">
                    <button className="button is-text" onClick={this.handleForgotPassword}>{!this.state.forgotPassword ? '¿Olvidaste tu contraseña?' : 'Regresar'}</button>
                  </div>

                  <div className="notification has-text-centered" hidden={!this.state.showMessage}>
                    <p className="has-text-danger is-size-6">{this.state.message}</p>
                  </div>

                </form>
              </section>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Login